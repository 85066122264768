<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                  ref="year"
                  v-model="searchOptions.year"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">월별 채널별 예약현황</div>
              <div v-show="titleYear" class="header-caption">[영업년도 : {{titleYear}}년도]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bodyGrid"
                v-bind="bodyGridProps"
                :dataSource="monthlyReservationStatusByChannels"
                :aggregates="bodyGridAggregates"
                @headerCellInfo="onBodyGridHeaderCellInfo"
                @queryCellInfo="onBodyGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import ReportView from '@/components/common/report/ReportView';
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import {numberWithCommas} from "@/utils/number";
import {getEndOfMonth, getTodayNavigationDate} from "@/utils/date";
import {
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import {mapGetters} from "vuex";

export default {
  name: "MonthlyReservationStatusByChannel",
  components: {
    EjsGridWrapper,
    InputNumber,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      searchOptions: {
        year: null,
      },
      titleYear: null,
      monthlyReservationStatusByChannels: [],
      monthlyReservationStatusByChannelLength: 0,
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {},
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        groupSettings: {
          columns: ["partDiv"],
          showDropArea: false,
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: true,
        allowExcelExport: true,
        noColumnWidth: 34,
        columns: [
          {
            field: "partDiv",
            visible: false,
          },
          {
            field: "partDivName",
            headerText: "부별",
            type: "string",
            minWidth: 16,
            width: 42,
            textAlign: "center",
          },
          {
            field: "resveChannelName",
            headerText: "채널",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            headerText: "1월",
            columns: [
              {
                field: "weekdayMonth01",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth01",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth01",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "2월",
            columns: [
              {
                field: "weekdayMonth02",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth02",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth02",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "3월",
            columns: [
              {
                field: "weekdayMonth03",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth03",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth03",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "4월",
            columns: [
              {
                field: "weekdayMonth04",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth04",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth04",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "5월",
            columns: [
              {
                field: "weekdayMonth05",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth05",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth05",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "6월",
            columns: [
              {
                field: "weekdayMonth06",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth06",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth06",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "상반기",
            columns: [
              {
                field: "firstHalfWeekday",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "firstHalfWeekend",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "firstHalfTotal",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "7월",
            columns: [
              {
                field: "weekdayMonth07",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth07",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth07",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "8월",
            columns: [
              {
                field: "weekdayMonth08",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth08",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth08",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "9월",
            columns: [
              {
                field: "weekdayMonth09",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth09",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth09",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "10월",
            columns: [
              {
                field: "weekdayMonth10",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth10",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth10",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "11월",
            columns: [
              {
                field: "weekdayMonth11",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth11",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth11",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "12월",
            columns: [
              {
                field: "weekdayMonth12",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendMonth12",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "totalMonth12",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "하반기",
            columns: [
              {
                field: "secondHalfWeekday",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "secondHalfWeekend",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "secondHalfTotal",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "합계",
            columns: [
              {
                field: "weekdayTotal",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "weekendTotal",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
              {
                field: "total",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 62,
                textAlign: "right",
              },
            ],
          },
        ],
      };
    },
    bodyGridAggregates() {
      return [
        {
          columns: [
            {
              field: "resveChannelName",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items[0]?.partDivName + " 소계";
              },
            },
            {
              field: "weekdayMonth01",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth02",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth03",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth04",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth05",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth06",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth07",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth08",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth09",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth10",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth11",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayMonth12",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth01",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth02",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth03",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth04",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth05",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth06",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth07",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth08",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth09",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth10",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth11",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendMonth12",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth01",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth02",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth03",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth04",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth05",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth06",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth07",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth08",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth09",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth10",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth11",
              aggregationType: "GroupSum",
            },
            {
              field: "totalMonth12",
              aggregationType: "GroupSum",
            },
            {
              field: "firstHalfWeekday",
              aggregationType: "GroupSum",
            },
            {
              field: "firstHalfWeekend",
              aggregationType: "GroupSum",
            },
            {
              field: "firstHalfTotal",
              aggregationType: "GroupSum",
            },
            {
              field: "secondHalfWeekday",
              aggregationType: "GroupSum",
            },
            {
              field: "secondHalfWeekend",
              aggregationType: "GroupSum",
            },
            {
              field: "secondHalfTotal",
              aggregationType: "GroupSum",
            },
            {
              field: "weekdayTotal",
              aggregationType: "GroupSum",
            },
            {
              field: "weekendTotal",
              aggregationType: "GroupSum",
            },
            {
              field: "total",
              aggregationType: "GroupSum",
            },
          ]
        },
        {
          columns: [
            {
              field: "resveChannelName",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "weekdayMonth01",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth02",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth03",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth04",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth05",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth06",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth07",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth08",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth09",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth10",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth11",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayMonth12",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth01",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth02",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth03",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth04",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth05",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth06",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth07",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth08",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth09",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth10",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth11",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendMonth12",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth01",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth02",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth03",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth04",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth05",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth06",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth07",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth08",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth09",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth10",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth11",
              aggregationType: "TotalSum",
            },
            {
              field: "totalMonth12",
              aggregationType: "TotalSum",
            },
            {
              field: "firstHalfWeekday",
              aggregationType: "TotalSum",
            },
            {
              field: "firstHalfWeekend",
              aggregationType: "TotalSum",
            },
            {
              field: "firstHalfTotal",
              aggregationType: "TotalSum",
            },
            {
              field: "secondHalfWeekday",
              aggregationType: "TotalSum",
            },
            {
              field: "secondHalfWeekend",
              aggregationType: "TotalSum",
            },
            {
              field: "secondHalfTotal",
              aggregationType: "TotalSum",
            },
            {
              field: "weekdayTotal",
              aggregationType: "TotalSum",
            },
            {
              field: "weekendTotal",
              aggregationType: "TotalSum",
            },
            {
              field: "total",
              aggregationType: "TotalSum",
            },
          ]
        }
      ];
    },
  },
  mounted() {},
  methods: {
    numberWithCommas,
    async onViewButtonClicked() {
      const fromDate = this.searchOptions.year + "-01-01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), 12);
      const toDate = this.searchOptions.year + "-12-" + monthLastDay;

      const reservationInfos = await GolfErpAPI.fetchReservationInfo({
        fromDate: fromDate,
        toDate: toDate,
      });

      const partDivs = commonCodesGetCommonCode("PART_DIV");
      const months = ["01","02","03","04","05","06","07","08","09","10","11","12"];

      const resveChannelComCode = commonCodesGetCommonCode("RESVE_CHANNEL");

      let monthlyReservationStatusByChannels = [];

      partDivs.forEach(partDiv => {
        resveChannelComCode.forEach((resveChannel, idx) => {
          let obj = {
            partDiv: partDiv.comCode,
            partDivName: partDiv.comName,
            isPartDivNameVisible: idx === 0,
            resveChannel: resveChannel.comCode,
            resveChannelName: resveChannel.comName,
          };
          months.forEach(month => {
            obj["weekdayMonth" + month] =
              reservationInfos.filter(resveInfo =>
                resveInfo.resveDate.substring(5, 7) === month &&
                resveInfo.bsnCode === "WEEKDAY" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["weekendMonth" + month] =
              reservationInfos.filter(resveInfo =>
                resveInfo.resveDate.substring(5, 7) === month &&
                resveInfo.bsnCode === "WEEKEND" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["totalMonth" + month] =
              reservationInfos.filter(resveInfo =>
                resveInfo.resveDate.substring(5, 7) === month &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["firstHalfWeekday"] =
              reservationInfos.filter(resveInfo =>
                ["01","02","03","04","05","06"].includes(resveInfo.resveDate.substring(5, 7)) &&
                resveInfo.bsnCode === "WEEKDAY" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["firstHalfWeekend"] =
              reservationInfos.filter(resveInfo =>
                ["01","02","03","04","05","06"].includes(resveInfo.resveDate.substring(5, 7)) &&
                resveInfo.bsnCode === "WEEKEND" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["firstHalfTotal"] =
              reservationInfos.filter(resveInfo =>
                ["01","02","03","04","05","06"].includes(resveInfo.resveDate.substring(5, 7)) &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["secondHalfWeekday"] =
              reservationInfos.filter(resveInfo =>
                ["07","08","09","10","11","12"].includes(resveInfo.resveDate.substring(5, 7)) &&
                resveInfo.bsnCode === "WEEKDAY" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["secondHalfWeekend"] =
              reservationInfos.filter(resveInfo =>
                ["07","08","09","10","11","12"].includes(resveInfo.resveDate.substring(5, 7)) &&
                resveInfo.bsnCode === "WEEKEND" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["secondHalfTotal"] =
              reservationInfos.filter(resveInfo =>
                ["07","08","09","10","11","12"].includes(resveInfo.resveDate.substring(5, 7)) &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["weekdayTotal"] =
              reservationInfos.filter(resveInfo =>
                resveInfo.bsnCode === "WEEKDAY" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["weekendTotal"] =
              reservationInfos.filter(resveInfo =>
                resveInfo.bsnCode === "WEEKEND" &&
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
            obj["total"] =
              reservationInfos.filter(resveInfo =>
                resveInfo.partDiv === partDiv.comCode &&
                resveInfo?.reservationConfirm?.reservationMember?.resveChannel === resveChannel.comCode
              ).length;
          });
          monthlyReservationStatusByChannels.push(obj);
        });
      });

      this.monthlyReservationStatusByChannels = monthlyReservationStatusByChannels;
      this.monthlyReservationStatusByChannelLength = monthlyReservationStatusByChannels.length;
      this.titleYear = this.searchOptions.year;
    },
    print() {
      if (this.monthlyReservationStatusByChannels.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: {
            monthlyReservationStatusByChannels: this.monthlyReservationStatusByChannels,
            year: this.titleYear,
          },
          username: this.username,
          uniqueCode: this.$options.name,
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    onBodyGridHeaderCellInfo(args) {
      const {
        node,
      } = args;

      node.style.paddingLeft = "4px";
      node.style.paddingRight = "4px";
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "partDivName") {
        if (!data.isPartDivNameVisible) {
          cell.innerText = "";
        }
      }

      if (
        field &&
        (field.startsWith("firstHalf") ||
          field.startsWith("secondHalf") ||
          field.endsWith("total") ||
          field.endsWith("Total"))
      ) {
        cell.style.backgroundColor = "#fef2cb";
      }
    },
  }
};
</script>
